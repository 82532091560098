<template>
    <div class="page">
        <div class="header">
            <div @click="getBack">
                <img class="img" src="../../assets/img/arrow.png" alt="" />
            </div>
            <div class="logg">{{ $t('注册') }}</div>
            <div></div>
        </div>
        <br><br>
        <div class="content">
            <van-form>
                <div class="phone">
                    <div style="height:1.4rem; width:160px;border-radius: 0.26667rem;padding-left: 8px; background-color:#323233;  color: #FFFFFF;">
                        <fdSelect :list="option1" v-model="selected"></fdSelect>
                    </div>
                    <!-- <van-field class="input prefix" v-model="prefix" /> -->
                    <!-- <van-dropdown-menu >
            <van-dropdown-item v-model="value" :options="option1" />
          </van-dropdown-menu> -->
                    <van-field class="input" v-model="mobile" :placeholder="$t('输入手机号')" />
                </div>
                <van-field class="input" v-model="username" :placeholder="$t('请输入邮箱')" />
                <!-- <div class="captcha" v-show="smswithc">
          <van-field v-model="captcha" :placeholder="$t('输入验证码')" />
          <van-button class="textBtn" plain type="info" @click="getcode()">
            <span v-show="show">{{ tishi }}</span>
            <span v-show="!show">{{ count }} s</span>
          </van-button>
        </div> -->
                <van-field class="input" v-model="password" type="password" :placeholder="$t('输入密码')" />
                <van-field class="input" v-model="password2" type="password" :placeholder="$t('再次输入密码')" />
                <van-field class="input" v-model="invit" :placeholder="$t('请输入邀请码')" />
            </van-form>
        </div>
        <ROBOT />
        <div style="margin: 16px; padding: 32px 60px 0">
            <van-button class="btn" round block type="info" @click="register">{{
                $t('注册')
                }}</van-button>
        </div>
        <br>
        <div class="signUp" style="text-align: center;">
            <router-link to="/login"> {{ $t('立即登录') }}</router-link>
        </div>
    </div>
</template>
<script>
import ROBOT from '@/components/robot'
import { Button, NavBar, Field, DropdownMenu, DropdownItem, Toast } from 'vant'
import { apiRegister, getUrlKey, apiRegss, apismssWitch, apiLogin, apigetGuoma } from '@/http/api/' // 导入我们的api接口
import fdSelect from "@/components/fd-select/fd-select.vue";

export default {
    name: 'welcome',
    components: {
        ROBOT,
        fdSelect,
        [Button.name]: Button,
        [NavBar.name]: NavBar,
        [Field.name]: Field,
        [DropdownMenu.name]: DropdownMenu,
        [DropdownItem.name]: DropdownItem
    },
    data() {
        return {
            // prefix: '+234',
            value: '+234',
            mobile: '',
            password: '',
            password2: '',
            captcha: '',
            invit: '',
            username: '',
            smswithc: false,
            option1: [

            ],
            count: 60,
            show: true,
            timer: null,
            tishi: this.$t('获取邮箱验证码'),
            selected: {}, //这是拿到的数据
        }
    },

    watch: {
        mobile: function() {
            this.mobile = this.mobile.replace(/[^\d]/g, '')
        }
    },
    mounted() {
        apismssWitch({}).then((res) => {
            if (res.info.sta_tankuang == '1') {
                this.smswithc = true;
            } else {
                this.smswithc = true;
            }

        })
    },
    created(e) {
        var url = window.location.href
        let code = getUrlKey('code', url)
        this.invit = code;
        apigetGuoma({

        }).then((res) => {
            var that = this;
            console.log(res);
            var arr = res.info;
            for (let i = 0; i < arr.length; i++) {
                let arr2 = new Array();
                arr2.text = arr[i].code;
                arr2.value = arr[i].code
                this.option1.push(arr2);
                console.log(this.option1)

            }


            that.value = arr[0].code;
            this.option1 = this.option1.map((v, index) => {
                return {
                    code: v.text,
                    img: require(`@/static/images/country/${v.text.replace(/\+/g,'')}.png`),
                    id: index + ''
                }
            })
            this.selected = {
                ...this.option1[0]
            }
            console.log(this.selected, 9999)

        });
    },
    methods: {
        isEmail(str) {
            return /^[a-zA-Z0-9_-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/.test(str);
        },
        getCode2() {
            const TIME_COUNT = 60
            if (!this.timer) {
                this.count = TIME_COUNT
                this.show = false
                this.timer = setInterval(() => {
                    if (this.count > 0 && this.count <= TIME_COUNT) {
                        this.count--
                    } else {
                        this.show = true
                        clearInterval(this.timer)
                        this.timer = null
                    }
                }, 1000)
            }
        },
        getcode() {
            console.log(this.selected, 888888)
            if (!this.mobile) {
                Toast.fail(this.$t('手机号码不能为空'))
                return
            }
            if (!this.username) {
                Toast.fail(this.$t('账号不存在'))
                return
            }

            Toast.loading({
                duration: 0,
                message: this.$t('加载中'),
                forbidClick: true
            })
            apiRegss({
                username: this.username,
                mobile: this.mobile,
                type: 'reg',
                optionval: this.selected.code
            }).then((res) => {
                Toast.clear()
                console.log(res)
                if (res.status) {
                    Toast.success(this.$t(res.info))
                    this.getCode2()
                } else {
                    Toast.fail(this.$t(res.info))
                }
            })
        },
        register() {
            console.log(this.selected, 55555)
            let form = {
                invit: this.invit,
                mobile: this.mobile,
                password: this.password,
                password2: this.password2,
                username: this.username,
                code: this.captcha,
                optionval: this.selected.code
            }
            Toast.loading({
                duration: 0,
                message: this.$t('加载中'),
                forbidClick: true
            })
            apiRegister(form).then((res) => {
                console.log(res)
                if (res.status == 1) {
                    Toast.clear()
                    setTimeout(() => {
                        // 存储用户登录状态
                        Toast.success(this.$t('注册成功'))
                        apiLogin({
                            mobile: this.mobile,
                            password: this.password,
                            optionval: this.selected.code
                        }).then((res) => {
                            localStorage.setItem('key', res.userid)
                            localStorage.setItem('msg', 1)

                            this.$router.replace('/')
                        })

                    }, 1500)
                } else {
                    Toast.fail(this.$t(res.info))
                }
            })
        },

        captchaEvent() {
            this.textContent = '12miao'
        },
        getBack() {
            this.$router.back()
        },
        onSubmit() {
            console.log('zhuce ')
        }
    }
}
</script>
<style lang="scss" scoped>
.page {
    .van-dropdown-menu__bar {
        background-color: #2b2b2b !important;
    }

    .header {
        padding: 0 10px;
        height: 56px;
        line-height: 56px;
        display: flex;
        justify-content: space-between;

        .close {
            width: 28px;
            height: 28px;
        }

        .img {
            width: 40px;
        }

        .logg {
            font-size: 40px;
            font-family: PingFang SC-中粗体, PingFang SC;
            font-weight: normal;
            color: #ffffff;
        }
    }

    .van-field {
        background-color: #2b2b2b !important;
    }

    .van-field__control {
        background-color: #2b2b2b !important;
    }

    .van-dropdown-menu .van-dropdown-menu__bar {
        background-color: #2b2b2b !important;
    }

    .btn {
        box-shadow: 0px 10px 20px #0d9135;
        font-size: 40px;
        height: 103px;
        background: linear-gradient(1deg, #0d9135, #ffedd8);
        border: none;
        color: #000;
    }

    .phone {
        display: flex;
    }

    .captcha {
        height: 90px;
        padding: 10px;
        margin-bottom: 30px;
        display: flex;
        border: 1px solid rgba(255, 255, 255, 0.20000000298023224);
        border-radius: 20px;

        .textBtn {
            width: 500px !important;
        }

        ::v-deep .van-button--plain.van-button--info {
            color: #0d9135;
        }
    }

    .content {
        margin-top: 90px;
        padding: 0 36px;

        .prefix {
            width: 180px;
            margin-right: 30px;

            ::v-deep input.van-field__control {
                color: #0d9135;
            }
        }
    }

    ::v-deep .van-button--plain {
        background-color: #2b2b2b;
    }

    ::v-deep.van-button--info {
        border: none;
    }

    ::v-deep .van-dropdown-menu__bar {
        height: 110px;
    }
}
</style>