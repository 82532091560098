<template>
    <layout>
        <template #left> <span></span></template>
        <template #title>{{ $t('transfer.title') }}</template>
        <template #content>
            <div class="content">

                <div class="box">
                    
                    <div class="title">
                        <h2>{{ $t('transfer.transfer_money') }}</h2>
                        <p>{{ $t('transfer.current_balance') }}: ${{user.usdt}}</p>
                    </div>
                    
                    <form>
                        <div>
                            <label for="">{{ $t('transfer.receiver_username') }}</label>
                            <div>
                                <input type="text" :placeholder="$t('transfer.receiver_username')" maxlength="100" v-model="form.username">
                            </div>
                        </div>
                        
                        <div>
                            <label for="">{{ $t('transfer.amount') }}</label>
                            <div>
                                <input type="number" :placeholder="$t('transfer.transfer_money')" onkeyup="this.value = this.value.replace (/^\.|[^\d\.]/g, '')" v-model="form.amount">
                            </div>
                        </div>
                        
                        <!-- <div class="min_max">
                            <div>
                                <span>Min Transfer Amount</span>
                                <span>${{transfer.min}}</span>
                            </div>
                            <div>
                                <span>Max Transfer Amount</span>
                                <span>${{transfer.max}}</span>
                            </div>
                        </div> -->
                        <!-- <div>
                            This function enables key-free transfers between VIP users.
                        </div> -->

                        <!-- 提交 -->
                       <div class="btns">
                            <button type="submit" class="transfer_money" @click="submit">
                                <span>{{ $t('transfer.transfer_money') }}</span>
                            </button>
                       </div>
                    </form>

                </div>
            </div>
        </template>
    </layout>
</template>
<script>
import { apiUser_mas2, transfer } from "@/http/api/";
import { baseURL } from '@/http/request';
import { NavBar, Progress, Icon, Button, Toast } from 'vant'

export default {
    components: {},
    data() {
        return {
            baseURL: baseURL,
            transfer: {
                min: 10,
                max: 10000000,
            },
            user: {},
            form: {
                username: '',
                amount: 10
            },
        }
    },
    methods: {

        submit(){
            this.form.userid = this.token;
            // console.log(this.form)
            if(this.form.amount < this.transfer.min || this.form.amount > this.transfer.max){
                Toast.fail(this.$t('api.超出限定金额范围'));
                return;
            }
            transfer(this.form).then(res=>{
                // console.log(res);
                if(res.err == 1){
                    Toast.fail(this.$t('api.'+res.msg));
                    return;
                }
                if(res.err == 2){
                    Toast.fail(this.$t(res.msg));
                    return;
                }
                Toast.success(this.$t('api.'+res.msg));
            });
        }
    },
    created() {
        var token = localStorage.getItem("key");
        if (!token) {
            this.$router.replace("/login");
        }
        this.token = token;

        // 获取用户信息
        apiUser_mas2({
            userid: token
        }).then((res) => {
            // 未登录踢下线
            if (res.status == 10) {
                this.$router.replace('/login')
            }
            this.user = res.user
        })
    }
}
</script>
<style lang="scss" scoped>
// --gradient-bg: linear-gradient(110deg, var(--grad-one) -4%, var(--grad-two) 35%, var(--grad-three) 124%);
.content {
    padding: 0 40px;

    .box {
        border: 1px solid #ffffff2b;

        border-radius: 16px;
        -webkit-border-radius: 16px;
        -moz-border-radius: 16px;
        -ms-border-radius: 16px;
        -o-border-radius: 16px;

        .title {
            padding-top: 30px;
            border-bottom: 1px solid #ffffff2b;

            h2 {
                margin: 10px 0;
                padding: 0 30px;
            }
            p {
                font-size: 30px;
                padding: 0 30px;
                margin-top: 0;
            }
        }

        form {
            margin: 30px;
            overflow: hidden;
            font-size: 28px;

            >div {
                margin-bottom: 30px;

                label {
                    display: block;
                    margin-bottom: 10px;

                }
                >div {
                    padding: 15px 20px;
                    border: 1px solid #ffffff2b;
                    overflow: hidden;

                    border-radius: 10px;
                    -webkit-border-radius: 10px;
                    -moz-border-radius: 10px;
                    -ms-border-radius: 10px;
                    -o-border-radius: 10px;
                }
                input {
                    width: 100%;
                    max-height: 100px;
                    color: #fff;
                }
            }

            .min_max {
                >div {
                    display: flex;
                    justify-content: space-between;
                }
            }
        }
    }


    .btns {
        --grad-one: #5C6BFF;
        --grad-two: #C57CFE;
        --grad-three: #D6085D;
        --gradient-bg: linear-gradient(110deg, var(--grad-one) -4%, var(--grad-two) 35%, var(--grad-three) 124%);

        button.transfer_money {
            width: 100%;
            height: 78px;
            // padding: 0;
            // color: #fff;
            // mask-size: 100% 100%;
            // -webkit-mask-image: url('@/assets/btns/button.png');
            // mask-image: url('@/assets/btns/button.png');

            padding: 0;
            color: #fff;
            -webkit-mask-repeat: no-repeat;
            mask-repeat: no-repeat;
            -webkit-mask-size: 100% 100%;
            mask-size: 100% 100%;
            -webkit-mask-image: url('@/assets/btns/button.png');
            mask-image: url('@/assets/btns/button.png');
            -webkit-mask-position: center center;
            mask-position: center center;
            -webkit-mask-repeat: no-repeat;
            mask-repeat: no-repeat;

            background: var(--gradient-bg);
            cursor: pointer;
            border: 0;

            span {
                padding: 8px 12px;
                font-size: 28px;

                // width: 100%;
                // height: 100%;
                display: block;


                background-color: var(--gradient-bg);

                // background-color: linear-gradient(110deg, #5C6BFF -4%, #C57CFE 35%, #D6085D 124%);
                text-align: center;
            }
        }
    }
}
</style>