<template>
    <layout :isShowFooter="false">
        <!-- <template #left> <span></span></template> -->
        <template #title>{{ $t('转帐日志') }}</template>
        <template #content>
            <div class="content">
                <!-- 列表 -->
                <ul class="list">
                    <li>
                        <span>{{ $t('转账者') }}</span>
                        <span>{{ $t('接收者') }}</span>
                        <span>{{ $t('金额') }}</span>
                        <span>{{ $t('转账日期') }}</span>
                    </li>
                    <li v-for="(item,index) in list" :key="index">
                        <span>{{item.transfer_user}}</span>
                        <span>{{item.receiver_user}}</span>
                        <span>{{item.actual_amount}}</span>
                        <span>{{item.transfer_time}}</span>
                    </li>
                </ul>
                <div class="load_more" @click="loadMore()" v-if="isLastPage==false">
                    <a href="javascript:;">{{ $t('加载更多') }}</a>
                </div>
            </div>
        </template>
    </layout>
</template>
<script>
import { getTransferLog } from "@/http/api/";
import { baseURL } from '@/http/request';
import { NavBar, Progress, Icon, Button, Toast } from 'vant'

export default {
    components: {},
    data() {
        return {
            baseURL: baseURL,
            list: [],
            page: {
                current: 1,
                size: 10,
                pages: 0,
                total: 0
            },
            loading: false,
            isLastPage: false

        }
    },
    methods: {
        getTransferLogList() {
            if(this.loading){
                return false;
            }
            this.loading = true;
            getTransferLog({ userid: this.token, page: this.page.current, size: this.page.size }).then(res => {
                // console.log(res);
                this.loading = false;
                if (res.err == 0) {
                    this.list = [...this.list,...res.data];
                    this.page = res.page;
                    if(this.page.current >= this.page.pages){
                        this.isLastPage = true;
                    }
                }
            });
        },
        loadMore() {
            this.page.current  = this.page.current + 1;
            // console.log(this.page)
            if(this.page.current <= this.page.pages){
                this.getTransferLogList();
            }
            
        }
    },
    created() {
        var token = localStorage.getItem("key");
        if (!token) {
            this.$router.replace("/login");
        }
        this.token = token;

        // 获取VIP等级列表
        this.getTransferLogList();
    }
}
</script>
<style lang="scss" scoped>
.content {
    padding: 0 40px;

    ul.list {
        li {
            display: flex;
            justify-content: space-between;
            height: 60px;
            align-items: center;

            span {
                display: inline-block;
                flex: 1 1 25%;
                text-align: center;
            }
        }
    }

    .load_more {
        height: 80px;
        line-height: 80px;
        text-align: center;
        a {
            font-size: 28px;
            font-style: italic;
        }
    }
    
}
</style>