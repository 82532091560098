<template>
    <div class="page">
        <div class="header">
            <div @click="getBack" class="arrow">
                <img class="img" src="../../assets/img/arrow.png" alt="" />
            </div>
            <div class="logg">{{ $t('充值记录') }}</div>
            <div></div>
        </div>
        <div class="choose">
            <van-tabs v-model="active">
                <van-tab :title="$t('充值记录')" name="1">
                    <!-- <div class="pitera">
            <img src="../../assets/img/机器人.png" alt="" />
            <div>暂无数据</div>
          </div> -->
                    <van-list v-model="loading" :finished="finished" :finished-text="$t('暂无数据')" @load="onLoad">
                        <van-cell v-for="item in list" :key="item.id">
                            <div class="learn">
                                <div class="box">
                                    <div>{{item.create_time}}</div>
                                    <div class="kna-status" v-if="item.status == 0">
                                        <div> {{$t('待审核')}}</div>
                                    </div>
                                    <div class="kna-status" style="color: green;" v-if="item.status == 1">
                                        <div>{{$t('成功')}}</div>
                                    </div>
                                    <div class="kna-status" style="color:red;" v-if="item.status == 2">
                                        <div>{{$t('失败')}}</div>
                                    </div>
                                    <div class="kna-status" style="color:red;" v-if="item.status == 3">
                                        <div>{{$t('审核中')}}</div>
                                    </div>
                                </div>
                                <div class="box">
                                    <div>{{ $t('数量') }}: </div>
                                    <div style="font-size: 20px; color: #fff">{{ item.amount }}USDT</div>
                                </div>
                                <!-- <div class="box">
                  <div>{{ $t('recharge.订单号') }}:</div>
                  <div>
                    <span>{{ item.hash }}</span>
                    <span class="copy" @click="copyEvent(item.hash)">复制</span>
                  </div>
                </div> -->
                                <div class="box">
                                    <div>{{ $t('交易编号') }}:</div>
                                    <div style="font-size: 8px;">{{ item.id }}</div>
                                </div>
                                <div class="box">
                                    <div>{{ $t('描叙') }}:</div>
                                    <div>{{$t(item.memo)}}</div>
                                </div>
                            </div>
                        </van-cell>
                    </van-list>
                </van-tab>
                <van-tab :title="$t('提款记录')" name="2">
                    <van-list v-model="loading" :finished="finished" :finished-text="$t('暂无数据')" @load="onLoad">
                        <van-cell v-for="item in list" :key="item.id">
                            <div class="learn">
                                <div class="box">
                                    <div>{{item.create_time}}</div>
                                    <div class="kna-status" v-if="item.status == 0">
                                        <div> {{$t('待审核')}}</div>
                                    </div>
                                    <div class="kna-status" style="color: green;" v-if="item.status == 1">
                                        <div>{{$t('成功')}}</div>
                                    </div>
                                    <div class="kna-status" style="color:red;" v-if="item.status == 2">
                                        <div>{{$t('失败')}}</div>
                                    </div>
                                    <div class="kna-status" style="color:red;" v-if="item.status == 3">
                                        <div>{{$t('审核中')}}</div>
                                    </div>
                                </div>
                                <div class="box">
                                    <div>{{ $t('数量') }}: </div>
                                    <div style="font-size: 20px; color: #fff">{{ item.amount }}USDT</div>
                                </div>
                                <!-- <div class="box">
                  <div>{{ $t('recharge.订单号') }}:</div>
                  <div>
                    <span>{{ item.hash }}</span>
                    <span class="copy" @click="copyEvent(item.hash)">复制</span>
                  </div>
                </div> -->
                                <div class="box">
                                    <div>{{ $t('交易编号') }}:</div>
                                    <div style="font-size: 8px;">{{ item.hash }}</div>
                                </div>
                                <div class="box">
                                    <div>{{ $t('描叙') }}:</div>
                                    <div>{{$t(item.memo)}}</div>
                                </div>
                            </div>
                        </van-cell>
                    </van-list>
                </van-tab>
            </van-tabs>
        </div>
        <!-- <div class="footer">
      <span class="comeTo">{{ $t('总计') }} :</span>
      <span class="num"> 30</span>
      <span class="comeTo"> {{ $t('一次性总和') }} :</span>
      <span class="addUpTo">30</span>
    </div> -->
        <ROBOT></ROBOT>
    </div>
</template>
<script>
import ROBOT from '@/components/robot'
import { NavBar, Empty, PullRefresh, Toast, List } from 'vant'
import { apiliushui, apixiaofei, getUrlKey } from '@/http/api/'
export default {
    name: 'welcome',
    components: {
        ROBOT
    },
    data() {
        return {
            active: '1',
            list: [],
            loading: false,
            finished: false,
            token: "",
            page: 1
        }
    },
    created() {
        var token = localStorage.getItem("key");
        console.log("token", token);
        if (!token) {
            this.$router.replace("/login");
        }
        this.token = token;
        this.token = token;
        var url = window.location.href;
        let type = getUrlKey("type", url);
        if (type == 2) {
            this.active = 2;
        }
    },
    watch: {
        active(val) {
            this.page = 1;
            this.list = []
            this.loading = false
            this.finished = false
            this.onLoad()

        }
    },

    methods: {
        copyEvent(_data) {
            console.log('fuzhi ', _data)
            this.$copyText(String(_data))
            Toast(this.t('复制成功'))
        },
        getBack() {
            this.$router.back()
        },
        onLoad() {
            // 异步更新数据
            // setTimeout 仅做示例，真实场景中一般为 ajax 请求
            var that = this;
            Toast.loading({
                duration: 0,
                message: this.$t('加载中'),
                forbidClick: true,
            });
            apiliushui({
                userid: this.token,
                page: this.page,
                type: this.active
            }).then((res) => {
                Toast.clear();
                for (let i = 0; i < res.info.length; i++) {
                    that.list.push(res.info[i]);
                }
                // 数据全部加载完成
                if (res.info.length == 0) {
                    that.finished = true
                }
            });
            this.page++
            // 加载状态结束
            this.loading = false

        }
    }
}
</script>
<style lang="scss" scoped>
.page {

    .kna-status {
        position: absolute;
        text-align: center;

        right: .48309rem;
        padding: 0 .24155rem;
        font-size: .28986rem;
        height: .48309rem;
        line-height: .48309rem;
        color: #312693;
        border-radius: .72464rem;
        color: #ffcc8f;
        border: .02415rem solid #26b77e;
    }

    //   padding: 0 30px;

    .header {
        padding: 0 40px;
        height: 56px;
        line-height: 56px;
        display: flex;
        justify-content: space-between;

        .arrow {
            display: flex;
            align-items: center;
        }
        .close {
            width: 28px;
            height: 28px;
        }

        .img {
            width: 40px;
        }

        .logg {
            font-size: 40px;
            font-family: PingFang SC-中粗体, PingFang SC;
            font-weight: normal;
            color: #ffffff;
        }
    }

    .choose {
        margin-top: 60px;
        padding: 0 24px;

        .learn {
            margin-top: 30px;
            color: #fff;
            padding: 20px 25px;
            border-bottom: 1px solid #2a2c2e;

            .box {
                color: #888;
                margin-bottom: 20px;
                display: flex;
                justify-content: space-between;

                .copy {
                    margin-left: 8px;
                    padding: 7px 15px;
                    color: #2a2c2e;
                    font-size: 12px;
                    border-radius: 50px;
                    background-color: #0d9135;
                }
            }
        }
    }

    .pitera {
        color: #fff;
        line-height: 60px;
        font-size: 24px;
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        text-align: center;
    }

    .footer {
        position: fixed;
        font-size: 24px;
        color: #888;
        bottom: 0;
        height: 90px;
        width: 100%;
        text-align: center;
        line-height: 90px;
        background-color: #2a2c2e;
        border-radius: 30px 30px 0 0;

        .num {
            color: #0d9135;
            font-size: 30px;
            margin-right: 30px;
        }

        .comeTo {
            margin-right: 20px;
        }

        .addUpTo {
            font-size: 30px;
            color: #fff;
        }
    }
}
</style>