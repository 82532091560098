<template>
     <layout>
        <template #left> <span></span></template>
        <template #title>{{ $t('level.title') }}</template>
        <template #content>
           <div class="content">
                <!-- 列表 -->
               <ul class="list">
                   <li v-for="(item,index) in list" :key="index" @click="form.level_id = item.id">
                        <div class="box">
                            <div class="left">
                                <img :src="baseURL + item.icon" alt="">
                            </div>
                            <div class="center">
                                <h3>{{item.name}}</h3>
                                <p>{{ $t('level.daily_receive_limits') }}: ${{item.daily_transfer_amount}}</p>
                                <p>{{ $t('level.monthly_receive_limits') }}: ${{item.monthly_transfer_amount}}</p>
                            </div>
                            <div class="right">
                                <div>
                                    <div>${{ Math.round(item.upgrade_amount) }}</div>
                                    <div class="radio">
                                        <input type="radio" :checked="item.id == form.level_id">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="upper_right_corner">
                            <span>{{ $t('level.valid_days') }}: </span>
                            <span>{{item.valid_days}}</span>
                        </div>
                    </li>
               </ul>

               <!-- 提交 -->
               <div class="btns">
                    <button type="submit" class="upgrade_now" @click="submit">
                        <span>{{ $t('level.upgrade_now') }}</span>
                    </button>
               </div>
           </div>
        </template>
    </layout>
</template>
<script>
import { getLevel, buyLevelVIP } from "@/http/api/";
import { baseURL } from '@/http/request';
import { NavBar, Progress, Icon, Button, Toast } from 'vant'

export default {
    components: {},
    data() {
        return {
            baseURL: baseURL,
            list: [],
            form: {
                level_id: 0,
            }
        }
    },
    methods: {
        getLevelList(){
            getLevel({userid: this.token}).then(res=>{
                console.log(res);
                if(res.err == 0){
                    this.list = res.data;
                    console.log(this.list);
                }
            });
        },
        submit(){
            this.form.userid = this.token;
            console.log(this.form);
            buyLevelVIP(this.form).then(res=>{
                // console.log(res);
                if(res.err == 1){
                    Toast.fail(this.$t('api.'+res.msg));
                    return;
                }
                if(res.err == 3){
                    Toast.fail(this.$t('api.'+res.msg, {"failed":res.data.failed}));
                    return;
                }
                Toast.success(this.$t('api.'+res.msg));
            });
        }
    },
    created() {
        var token = localStorage.getItem("key");
        if (!token) {
            this.$router.replace("/login");
        }
        this.token = token;

        // 获取VIP等级列表
        this.getLevelList();
    }
}

</script>
<style lang="scss" scoped>
    // --gradient-bg: linear-gradient(110deg, var(--grad-one) -4%, var(--grad-two) 35%, var(--grad-three) 124%);
    .content {
        padding: 0 40px;
        ul.list {
            li {
                // display: inline-block;
                box-shadow: 0 2px 12px 0 rgba(199,107,79,.3);
                margin: 50px 0;
                padding: 40px 20px;
                cursor: pointer;
                border-radius: 40px;
                position: relative;
                background: #c3cdd1;

                &:nth-child(1){
                    background: #c3cdd1;
                }
                &:nth-child(2){
                    background: #a9d9f0;
                }
                &:nth-child(3){
                    background: #c76b4f;
                }
                &:nth-child(4){
                    background: #e24848;
                }
                &:nth-child(5){
                    background: gold;
                }
                &:nth-child(6){
                    background: #ebae3f;
                }
                &:nth-child(7){
                    background: #ebae3f;
                }
                &:nth-child(8){
                    background: #ebae3f;
                }
                &:nth-child(9){
                    background: #ebae3f;
                }
                &:nth-child(10){
                    background: #ebae3f;
                }

                div.box {
                    display:  flex;
                    justify-content: space-between;
                    gap: 20px;
                    .left {
                        width: 144px;
                        display: flex;
                        align-items: center;
                        
                        img {
                            width: 100%;
                        }
                    }
                    .center {
                        h3 {
                            font-size: 48px;
                            margin: 0;
                        }
                        p {
                            font-size: 26px;
                        }
                    }
                    .right {
                        // display: flex;
                        // align-items: center;

                        font-weight: 500;
                        font-size: 48px;
                        line-height: 40px;

                        >div {
                            position: relative;
                            top: 50%;
                            transform: translateY(-50%);
                            text-align: right;

                            div.radio {
                                margin-top: 20px;
                                input {
                                    border-radius: 50%!important;
                                    background: none;
                                    border: 1px solid #fff!important;
                                    width: 44px!important;
                                    height: 44px!important;
                                }

                                input:checked[type=radio] {
                                    background-color: #0d6efd;
                                    background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'><path fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/></svg>");
                                }
                            }
                        }
                    }
                }
                div.upper_right_corner {
                    position: absolute;
                    top: 30px;
                    right: 30px;
                    font-size: 32px;
                }
            }
        }

        .btns {
            --grad-one: #5C6BFF;
            --grad-two: #C57CFE;
            --grad-three: #D6085D;
            --gradient-bg: linear-gradient(110deg, var(--grad-one) -4%, var(--grad-two) 35%, var(--grad-three) 124%);

            button.upgrade_now {
                width: 100%;
                height: 78px;
                // padding: 0;
                // color: #fff;
                // mask-size: 100% 100%;
                // -webkit-mask-image: url('@/assets/btns/button.png');
                // mask-image: url('@/assets/btns/button.png');

                padding: 0;
                color: #fff;
                -webkit-mask-repeat: no-repeat;
                mask-repeat: no-repeat;
                -webkit-mask-size: 100% 100%;
                mask-size: 100% 100%;
                -webkit-mask-image: url('@/assets/btns/button.png');
                mask-image: url('@/assets/btns/button.png');
                -webkit-mask-position: center center;
                mask-position: center center;
                -webkit-mask-repeat: no-repeat;
                mask-repeat: no-repeat;

                background: var(--gradient-bg);
                cursor: pointer;
                border: 0;

                span {
                    padding: 8px 12px;
                    font-size: 28px;
         
                    // width: 100%;
                    // height: 100%;
                    display: block;

                    
                    background-color: var(--gradient-bg);

                    // background-color: linear-gradient(110deg, #5C6BFF -4%, #C57CFE 35%, #D6085D 124%);
                    text-align: center;
                }
            }
        }
    }
</style>