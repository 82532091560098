<template>
  <div class="layout">
    <div class="header" v-if="isShowHeader">
      <slot name="header">
        <van-nav-bar left-arrow @click-left="onClickLeft">
          <template #left>
            <slot name="left">
              <img class="back" src="@/assets/icon/back.png" alt="" style="height: 17px;width: 17px;"/>
            </slot>
          </template>
          <template #title> <slot name="title"></slot> </template>
          <template #right> <slot name="right"></slot> </template>
        </van-nav-bar>
      </slot>
    </div>
    <div class="content">
      <slot name="content"></slot>
    </div>
    <div v-if="isShowFooter" style="height: 10px"></div>
    <div class="footer" v-if="isShowFooter">
      <van-tabbar v-model="active" route>
        <van-tabbar-item
          v-for="item in tabbar"
          :key="item.title"
          :to="item.url"
        >
          <span>{{ item.title }}</span>
          <template #icon="props">
            <img :src="props.active ? item.iconA : item.icon" alt="" />
          </template>
        </van-tabbar-item>
      </van-tabbar>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    isShowHeader: {
      type: Boolean,
      default: true
    },
    isShowFooter: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      active: 0
    }
  },
  computed: {
    tabbar() {
      return [
        {
          title: this.$i18n.t('tabbar.首页'),
          icon: require('@/assets/tabbar/1.png'),
          iconA: require('@/assets/tabbar/1-1.png'),
          url: '/home'
        },
        {
          title: this.$i18n.t('tabbar.市场'),
          icon: require('@/assets/tabbar/2.png'),
          iconA: require('@/assets/tabbar/2-2.png'),
          url: '/market'
        },
     
        {
          title: this.$i18n.t('tabbar.团队'),
          icon: require('@/assets/tabbar/4.png'),
          iconA: require('@/assets/tabbar/4-4.png'),
          url: '/team'
        },
        {
          title: this.$i18n.t('tabbar.VIP'),
          icon: require('@/assets/tabbar/VIP.png'),
          iconA: require('@/assets/tabbar/VIP_active.png'),
          url: '/level'
        },
        {
          title: this.$i18n.t('tabbar.我的'),
          icon: require('@/assets/tabbar/5.png'),
          iconA: require('@/assets/tabbar/5-5.png'),
          url: '/my'
        },
		// {
		//   title: this.$i18n.t('客服'),
		//   icon: require('@/assets/tabbar/3.png'),
		//   iconA: require('@/assets/tabbar/3-3.png'),
		//   url: '/my'
		// }
      ]
    }
  },
  methods: {
    onClickLeft() {
      console.log('2312')
      this.$router.back()
    }
  }
}
</script>

<style lang="scss" scoped>
.layout {
  height: 100vh;
  width: 100vw;
  display: flex;
  overflow: hidden;
  background-color: #303133;
  flex-direction: column;
  .header {
    height: 90px;
    ::v-deep .van-hairline--bottom:after {
      border-bottom-width: 0px !important;
    }

    .van-nav-bar {
      height: 90px;
      background: #303133;
      ::v-deep .van-nav-bar__left {
        color: #fff;
      }
      ::v-deep .van-nav-bar__title {
        color: #fff;
        font-size: 40px;
      }
      ::v-deep .van-nav-bar__right {
        color: #fff;
      }
    }
  }
  .content {
    flex: 1;
    background: #303133;
    overflow-y: scroll;
    color: #fff;
  }
  .footer {
    height: 100px;
    background: #303133;
    .van-tabbar {
      height: 100px;
      background-color: #303133;
      .van-tabbar-item--active {
        color: #fff;
        background-color: #303133;
      }
    }
    ::v-deep .van-hairline--top-bottom::after {
      border-width: 0px !important;
    }
  }
}
</style>
